.loading-shading-mui {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.3);
}

.loading-icon-mui {
  position: absolute;
  font-size: 20px;
  top: calc(45% - 10px);
  left: calc(50% - 10px);
}
