.dx-datagrid-headers {
  color: $textColor;
  font-weight: bold;
  background-color: #E4E9F2;
  .dx-datagrid-table {
    .dx-row {
      td {
        font-size: .85rem;
        font-weight: bold;
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: #E4E9F2;
        text-transform: uppercase;
      }
    }
  }
  .dx-datagrid-rowsview {
    .dx-select-checkboxes-hidden {
      .dx-select-checkbox {
        display: block;
      }
    }
  }
}

.dx-datagrid {
  .dx-row {
    & > td {
      border-right: none !important;
      border-left: none !important;
    }
    &.dx-state-hover:not(.dx-header-row) {
      .grid-action-hover {
        display: table-cell !important;
      }
    }
    &.dx-header-row {
      background-color: #E4E9F2;
      .grid-action-hover, .grid-action-hover.hidden {
        background-color: transparent !important;
      }
      // transition: all .5s;
    }
    .grid-action-hover, .grid-action-hover.hidden {
      display: none;
      // transition: all .5s;
    }
    .grid-action-opacity {
      opacity: 0;
    }
  }
}
//keydown enter tab remove background
.grid-container {
  .dx-widget:not(.noBGSelect) {
    .cell-selected:not(.dx-editor-cell) {
      //color: #fafafa !important;
      background-color: #c7e2ff !important;
    }
  }
}

.dx-datagrid-table.dx-pointer-events-none.dx-datagrid-table-fixed {
  .dx-state-hover {
    background-color: $hoverColorDefault;
  }
}

//.dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed {
//  .grid-action-hover:not(.hidden) {
//    position: sticky;
//    right: 0;
//    padding: 0 10px;
//    //background-color: transparent;
//    border-left: 0 !important;
//    & > .show-btn-action{
//      display: flow-root !important;
//    };
//    & > *:first-child {
//      display: none;
//    };
//  }
//}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 37px;
  line-height: 37px;
}

.dx-texteditor-input {
  font-size: 14px;
  font-weight: 500;
}

.dx-datagrid-headers {
  dx-datagrid-table .dx-row > td {
    border-bottom: none;
  }

  .dx-header-row td {
    //text-align: center !important;
    font-weight: bold;
    background-color: $dataGridHeaderBgColor !important;
    border-bottom: none;
    overflow-y: visible;
  }
}

.grid_luc, .grid_luc_12{
  .dx-header-row td {
    background-color: $primaryColor !important;
  }
  .dx-datagrid-headers {
    td{
      color: white;
      padding: 0 10px !important;
      font-weight: 400 !important;
      height: 30px;
    }
    .dx-datagrid-text-content{
      white-space: pre-line;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .dx-datagrid-rowsview{
    color: #737373;
    td{
      font-size: 14px;
      line-height: 16px;
      padding: 0 10px !important;
      font-weight: 400 !important;
      height: 30px;
    }
    .dx-row:nth-child(odd) td{
      background-color: rgba(196, 196, 196, 0.5) !important;
    }
    .dx-row:nth-child(even) td{
      background-color: #C4C4C4 !important;
    }
  }
}

.grid_luc_12{
  .dx-datagrid .dx-row td, .dx-datagrid-text-content{
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

.dx-datagrid-content {
  position: static;
  .dx-datagrid-table {
    .dx-row {
      .dx-editor-cell {
        padding: 0 !important;
      }
    }
  }
}

.dx-datagrid .dx-row > td {
  padding-top: 8px;
  padding-bottom: 8px;
  //background-color: $dataGridBgOddColor;
}
.dx-datagrid .dx-row-alt > td {
  background-color: $dataGridBgEvenColor;
}
.dx-datagrid-rowsview .dx-selection.dx-row > td, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
  background-color: $dataGridSelectedBgColor;
  color: $dataGridSelectedFontColor;
  a {
    color: $dataGridSelectedFontColor !important;
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-edit.dx-command-edit-with-icons .dx-link, .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link, .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-edit.dx-command-edit-with-icons .dx-link {
  color: $dataGridActionButtonColor;
}
.dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: $primaryColor;
}
//.dx-pager .dx-pages .dx-selection, .dx-pager .dx-page-sizes .dx-selection {
//  background-color: $pagingPerPageBackground;
//  color: $pagingPerPageColor;
//}
.dx-datagrid {
  color: $dataGridCellFontColor;
}

.dx-pager .dx-pages .dx-info {
  color: $pagingInfoColor;
  opacity: 1;
}
.mgb-sub-15 {
  margin-bottom: -15px !important;
}
.grid-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  background-color: $gridContainerBgColor;
  border: 1px solid $gridContainerBorderColor;
  border-right: none;
  border-left: none;
}

.dx-datagrid .dx-header-row{
  td.iconGuids{
    display: inherit;
    border: none !important;
    background-color: transparent !important;
    position: absolute;
    left: 3px;
    z-index: 99999999;
  }
}

// .dx-datagrid .dx-row-lines {
//   td {
//     border-right: 1px solid rgb(236, 241, 246);
//   }
// }

.grid-title {
  color: #2f78b9;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 25px;
  padding: 2px 0 0 2px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: $dataGridBgEvenColor;
  /*background-image: linear-gradient(rgb(190, 204, 216), rgb(202, 218, 222), rgb(190, 204, 216));*/
}

.dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > td, .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child > tr > td {
  border-top: 1px solid $gridContainerBorderColor;
}

.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td, .dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
  background-color: #e6e6e6;
  color: #333;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content).dx-command-select,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content).dx-command-select {
  padding-left: 4px;
}
.dx-checkbox-icon {
  border: 2px solid $black;
}
.paging {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border: 1px solid #e0e0e0;
  border-left: none;
  border-right: none;
  padding: 5px;
  min-width: 330px;
  font-weight: bold;
  background-color: $white;

  &.paging-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
  .pageOf, .pagePer{
    margin-left:5px;
    margin-right:5px;
    text-align: center;
    height:25px;
    border-radius:5px;
  }
  .pagePer {
    background-color: #F5F5F5;
    &:hover, &:focus {
      background-color: $defaultColor;
    }
    select {
      padding-left: 9px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    svg {
      top: calc(50% - 11px);
    }
    fieldset {
      border: none;
    }
  }
  .pageOf {
    width: 1%;
    margin-top: 6px;
    -moz-appearance: textfield;

    .MuiInput-underline:before {
      border-bottom-width: 2px;
    }

    input {
      text-align: center;
      padding: 3px 0 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
  //.pageOf::-webkit-outer-spin-button,
  //.pageOf::-webkit-inner-spin-button {
  //  -webkit-appearance: none;
  //  margin: 0;
  //}
  .first span,
  .next span,
  .prev span,
  .last span
  {
    color: $fontColorDefault;
  }
  .first, .prev, .next, .last {
    padding: 6px 8px;
    border-radius: 50%;
    min-width: 18px;
  }
  .slash {
    margin: 0 3px;
    color: $fontColorDefault;
  }
}
.dx-invalid-message .dx-overlay-wrapper .dx-overlay-content {
  float: right;
  transform: translate(1px, -35px)!important;
}

.dx-popup-content:not(.dx-visibility-change-handler .dx-popup-content){
  padding: 0!important;
}

//.dx-scrollable-container {
//  background-color: $gridContainerBgColor;
//}

.dx-datagrid .dx-row-lines > td {
  border-bottom: 1px solid #E4E9F2;
}

.dx-datagrid .dx-row-lines > td.grid-action-hover {
  border-top: 1px solid #E4E9F2;
  border-bottom: 1px solid #E4E9F2;
}

//update UI paging on tablet and mobile view.
@media only screen and (max-width: 960px) {
  .paging-crm{

    .txt-line-number{
      display: none;
    }
    .hide-mobile, .pagePer{
      display: none;
    }
  }
}
//.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable > .dx-scrollable-wrapper{
//  padding-bottom: 10px;
//}

.dx-datagrid{
  .dx-row{
    td{
      min-height: 37px;
      font-size: 1rem;
      vertical-align: middle!important;
    }
  }
}
.disabledSearch{
  display: none !important;
}

.grid-full-width{
  position: relative;
  .grid-container{
    overflow-x: scroll ;
    padding-top: 38px;
    .toolbar-container{
      position: absolute;
      left: 15px;
      width: calc(100% - 30px);
      top: 0;
    }
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content),
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content) {
  padding-right: 7px;
  padding-left: 7px;
}


.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td .dx-texteditor,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-cell-modified:not(.dx-field-item-content) .dx-texteditor,
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-invalid:not(.dx-field-item-content) .dx-texteditor {
  padding-right: 7px;
  padding-left: 7px;
}

.dx-datagrid .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default,
.dx-datagrid-container .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  color: #334BAB
}

.dx-datagrid-text-content {
  overflow: visible;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td {
  .display-action-button-w29f2005{
    visibility: hidden;
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row.dx-state-hover > td {
  .display-action-button-w29f2005{
    visibility: visible;
  }
}

.dx-datagrid-revert-tooltip .dx-revert-button {
  height: 37px;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td {
  //transform: scale(1);
  //box-shadow: 4px 2px 5px rgba(0, 0, 0, 0.16);
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: $hoverColorDefault;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td.grid-action-hover {
  box-shadow: none;
}

.dx-datagrid-rowsview {
  border-top: none;
}

.dx-rtl .dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl .dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-filled.dx-show-clear-button .dx-texteditor-input,
.dx-rtl.dx-texteditor.dx-editor-outlined.dx-show-clear-button .dx-texteditor-input,
.dx-texteditor.dx-editor-filled .dx-placeholder:before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-placeholder:before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 8px 14px 8px;
}

.dx-datagrid .dx-editor-with-menu,
.dx-datagrid-container .dx-editor-with-menu {
  height: 37px;
}

.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content,
.dx-datagrid-container .dx-menu .dx-menu-item .dx-menu-item-content {
  padding: 7px 4px;
}

.dx-datagrid-filter-row .dx-menu {
  left: 0;
}

.dx-datagrid-focus-overlay:after {
  background-color: transparent;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 40px;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 40px;
}

.dx-device-mobile .dx-toolbar .dx-toolbar-items-container {
  height: 40px;
}

.btn-grid-action {
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.dx-highlight-outline {
  padding: 0;
}

.dx-command-edit.dx-command-edit-with-icons {
  padding: 3px !important;
}

.grid-container-paging {
  margin-bottom: 37px;
}
.dx-overlay-wrapper.dx-invalid-message.dx-invalid-message-always.dx-datagrid-invalid-message{
  .dx-overlay-content.dx-resizable {
    top: 82px !important
  }
}

.dx-searchbox.dx-editor-filled .dx-placeholder:before,
.dx-searchbox.dx-editor-filled .dx-texteditor-input,
.dx-searchbox.dx-editor-outlined .dx-placeholder:before,
.dx-searchbox.dx-editor-outlined .dx-texteditor-input {
  padding-left: 34px !important;
}

.dx-datagrid-headers .dx-header-row td{
  &.text-left {
    text-align: left !important;
  }
  &.text-right {
    text-align: right !important;
  }
}

.dx-datagrid-rowsview .dx-row > td,
.dx-datagrid-rowsview .dx-row > tr > td {
  //overflow: initial;
}

.grid-container {
  .dx-state-disabled .dx-widget,
  .dx-state-disabled.dx-widget {
    opacity: .2;
  }
}

.dx-datagrid .dx-datagrid-content {
  .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: 36px;
    line-height: 36px;
  }
}

.dx-datagrid .dx-edit-row {
  height: 100% !important;
}

//.dx-scrollable-content {
//  position: static;
//}
