#loading-Error500 {
  .loadingEr {
    margin: 200px auto;
  }

  .loading-title {
    font-size: 30px !important;
  }

  .loading-content{

  }
}
