/**
* @copyright 2019 @ DigiNet
* @author TRIHAO
* @create 11/8/2019
* @Example
*/

.MuiDialog-paperFullWidth.MuiDialog-paper {
  margin: 15px;
  width: calc(100% - 30px);
  //max-height: calc(100% - 30px);
}

.MuiDialogTitle-root {
  padding: 7px 15px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  //button {
  //  color: grey;
  //  z-index: 2000;
  //  padding: 6px;
  //}
}

//
.modal-scroll-content {
  height: calc(100% - 45px);
  overflow-x: hidden;
  overflow-y: auto;
}

.MuiDialogContent-dividers {
  padding-top: 5px !important;
}
