$widthSideBar: 60px;
$heightHeader: 48px;

.header-container{
  grid-area: header;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border: 1px solid #e7e7e7;
  border-top: 0;
  padding: 0 15px;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15);
  font-family: $fontDefault, sans-serif;
  position: fixed;
  top: 0;
  z-index: 251;

  .toogle-nav {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    color: #575757;
    width: $widthSideBar;
    height: $heightHeader;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 1.7rem;
      width: $heightHeader - 10px;
      height: $heightHeader - 10px;
      transition: all .3s;

      &.disabled {
        color: #ddd;
      }

      &:not(.disabled):hover,  &:not(.disabled).active {
        background-color: #ddd;
      }
    }
  }

  .view-logo {
    width: 80%;
    height: 100%;

    .logo {
      margin-left: 50px;
      min-width: 85px;
      cursor: pointer;

      img {
        max-height: 100%;
        max-width: 100%;
        min-width: 34px;
        object-fit: contain;
      }
    }
  }

  .header-filter {
    width: 100%;
    margin: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .set-filter {
      width: 100%;
      max-width: 580px;
    }

    .toogle-filter {
      display: none;
    }

    @media (max-width: 580px) {
      margin: 0 10px;
      justify-content: flex-end;
      .set-filter {
        display: none;
      }
      .toogle-filter {
        display: flex;
      }
    }
  }

  .logo {
    height: 100%;
  }

  .txt-number-badge {
    position: absolute;
    font-size: 10px;
    color: white;
    background: red;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    text-align: center;
    line-height: 15px;
    top: 0;
    right: 3px;
  }

  .view-menu{
    width: calc(100% - 215px);
    height: 100%;
  }
  .profile-view{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    hr{
      border-left: 1px solid $lightgrey;
      height: 25px;
      width: 1px;
    }
    span{
      font-size: 1rem;
      font-weight: 600;
      color: $grey;
      margin-right: 10px;
    }
    img{
      width: 35px;
      height: 35px;
      min-width: 35px;
      min-height: 35px;
      border-radius: 35px;
      margin-left: 10px;
      transition: all 0.2s;
      object-fit: cover;
      &:hover{
        transform: scale(1.05);
      }
    }
  }

  .switch-menu {
    .switch-menu-btn {
      color: $primaryColor;

    }

    .MuiButton-containedPrimary {
      color: $fontColor;
      background-color: $primaryColor;
    }

  }

  @media (max-width: 370px) {
    .switch-menu {
      display: none;
    }

    .toogle-nav i.disabled {
      color: inherit;
    }
  }

  .item-view{
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    //&:hover{
    //  transform: scale(1.2);
    //}
    &:hover {
      background-color: $hoverColor;
    }

    &.number-app span{
      background-color: $default;
    }
    .fas{
      color: $lightgrey;
      font-size: 1.8rem;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span{
      position: absolute;
      top: 5px;
      right: 2px;
      padding: 3px;
      border-radius: 16px;
      font-size: 10px;
      line-height: 16px;
      //width: 0.875rem;
      height: 0.975rem;
      background-color: #FE0101;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.popover-profile {
  .MuiPaper-elevation8{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }
  .MuiPopover-paper{
    width: 186px;
    z-index: 10;
    overflow: inherit;
    border-radius: 3px;
    border: 1px solid #E0E0E0;
    &:after{
      content: '';
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-bottom: 8px solid white;
      position: absolute;
      right: 14px;
      top:-8px
    }
    &:before{
      content: '';
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 10px solid rgba(0,0,0,0.12);
      position: absolute;
      right: 13px;
      top:-10px
    }
  }
  .MuiListItemText-root{
    margin-bottom: 0;
  }
  .MuiListItem-gutters {
    padding-left: 13px;
    padding-right: 13px;
  }
  .txtName, .txtDutyName{
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 18px;
    margin-top: 11px;
    color: #696B73;
  }
  .txtDutyName{
    font-size: 0.8rem;
    line-height: 15px;
    font-weight: 300;
    margin-top: 5px;
    color: #696B73;
  }
}

.tooltip.in{
  opacity: 1;
}

.popup-tooltip.popup-profile {
  right: 65px;
  left: auto !important;
}

.popup-tooltip-inner{
  font-family: $fontDefault,"Font Awesome 5 Free",sans-serif;
  padding: 5px 15px !important;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  background-color: $white !important;
  right: -60px;
  border-radius: 4px;
  box-shadow: -1px 2px 10px $lightgrey;
  border: none !important;
  overflow: hidden;
  .line-end{
    height: 1px;
    width: 100%;
    border-top-color: $bghover;
    position: absolute;
    bottom: -2px;
    line-height: 1px;
    margin: 0 !important;
  }
  &.popup-notify .line-end{
    bottom: -6px;
  }
  &.popup-notify, &.popup-message,&.popup-app{
    //min-width: 300px !important;
    img{
      width: 35px;
      height: 35px;
    }
    .item-menu {
      transition: all 0.2s;
    }
  }
  &.popup-profile{
    padding: 15px 0 !important;
    font-size: 14px !important;
    .item-menu{
      padding: 5px 20px;
      //transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
      }
      &:last-child{
        margin: 0;
        padding: 5px 20px;
      }
    }
  }
  &.popup-app{
    .flex-wrap{
      justify-content: space-around;
    }
    .item-menu{
      width: 25%;
      max-width: 25%;
      min-width: 25%;
      margin: 5px;
      .icon-app{
        width: 70px;
        height: 70px;
        margin-bottom: 5px;
        object-fit: cover;
        border-radius: 5px ;
        box-shadow: 1px 0px 10px $grey;
      }
      .app-name{
        white-space: pre;
      }
      &:last-child{
        border: none;
        margin: 5px;
        padding: 15px 0 0;
      }
    }
  }
  .maxline-two{
    max-height: 2.8rem;
    line-height: 1.4rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .maxline-one{
    max-height: 1.4rem;
    line-height: 1.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  img{
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    margin-right: 5px;
    border-radius: 4px;
  }
  .title-tooltip{
    margin: -5px -15px 0;
    height: 30px;
    background-color: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $fontColor;
    font-weight: bold;
  }
  .footer-tooltip{
    margin: 10px -15px -5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey;
    border-top: 1px solid $lightgrey;
    cursor: pointer;
  }
  .item-menu{
    align-items: center;
    cursor: pointer;
    position: relative;

    &:last-child{
      border-top: 1px solid $lightgrey ;
      margin: 0 -15px;
      padding: 5px 15px;
    }
    .fas{
      font-size: 1.2rem;
      margin-right: 10px;
      width: 22px;
    }
    span{
      font-size: 1rem;
      font-weight: 400;
    }
    .time{
      font-size: 0.8rem;
      line-height: 1.4rem;
      color: $grey;
    }
    .maxline-one, .maxline-two{
      color: $black;
    }
  }
  .is-read{
    .maxline-one, .maxline-two{
      font-weight: bold;
    }
  }
}

@media (max-width: 720px) {
  .header-container{
    height: 50px;
    padding: 0 5px;

    .view-logo {
      .logo span {
        display: none;
      }
    }

    .logo-company{
      display: none;
    }
    .MuiButtonGroup-contained{
      //display: none;
    }
    .item-view{
      padding: 10px 5px;
      margin: 0 5px;
    }
    .profile-view img{
      margin-left: 0;
    }
  }
  .popup-tooltip-inner{
    position: fixed;
    right: 7%;
    width: 85%;
    min-width: auto;
  }
}

@media only screen and (max-width: 992px) {
  .header-container {

  }
}
