.h3{
  margin-left: 15px
}

.fileInput{
  padding: 10px;
  margin: 15px;
  cursor: pointer;
}
.imgPreview{
  text-align: center;
  margin: 5px 15px;
  height: 200px;
  width: 100%;

  img{
    width: 100% !important;
    height: 100% !important;
  }
}
.previewText{
  width: 100%;
  margin-top: 20px
}

.submitButton{

  padding: 12px;
  margin-left: 10px;
  background: white;
  border: 4px solid lightgray;
  border-radius: 15px;
  font-weight: 700;
  font-size: 10pt;
  cursor: pointer;
  &:hover{
    background: #efefef
  }
}

.centerText{
  text-align: center;
  width: 500px
}
.new-account-img {
  display: block;
  width: 200px;
  max-height: 200px;
  border-radius: 50%;
}
.wth100{
  width: 100% !important;
}
