.user-container{
  display: flex;
  flex-wrap: wrap;
}
.pdl5{
  padding-left: 5px !important;
}
.pdb5{
  padding-bottom: 5px !important;
}

.pdb10{
  padding-bottom: 10px !important;
}
.fts12{
  font-size: 12px !important;
}
div .text-overflow{
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}
div .text-overflow-hg{
  width: auto;
  text-overflow: ellipsis;
  max-height: 35px;
  overflow: hidden;
}

div .text-overflowTitle{
  white-space: nowrap;
  width: auto;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdt50{
  padding-top: 50px !important;

}
.mrgt50{
  margin-top: 70px !important;
  justify-content: flex-end !important;

}
.mgdl{
 margin: 0 auto !important;
  display: block !important;
}

.imageUs {
  display: block;
  width: auto;
  max-height: 110px;
}
.imageUse {
  width: 100%;
  height:120px;
  object-fit: cover;
  border-radius: 4px;
}

.imgSales{
  width: 100%;
  max-height: 190px;
  margin-left: 0;
  margin-top: 2px;
  margin-right: 5px;
  margin-bottom: 10px;
  object-fit: contain;
}

#controlled-tab-example {
  min-width: 500px;
}
.success{
  color:#28a745;
}

.primary{
  color:#007bff;
}

.mgl20{
  margin-left: 20px !important;
}

.icon-image{
  position: absolute;
  color: red;
  font-size: 50px;
  bottom: 0;
  right: 0;
}
.pos{
  position: relative !important;
}