/** WARNING - USE AT OWN RISK */
/** IT IS AGAINST APPLE'S POLICY TO USE SF PRO FOR ANYTHING OTHER THAN iOS/tvOS/macOS/watchOS DESIGN & DEVELOPMENT */
/** ./fonts/SF-UI/font.css */
/** ./fonts/SF-UI/LICENSE.pdf */

/** 1. Copy/import this file into your main css/scss file */
/** 2. Change css font-family: to "SF Text", "SF Display" or "SF Mono" */
/** 3. Apply font-weight or italic to html elements */

/** THANK YOU */
/** I host these SF-UI on Cloudfront with SSL in all AWS regions for the best performance and reliability */
/** If you'd like to help offset costs, I'd very much appreciate a donation via Lisk https://lisk.io */
/** Address: 14987768355736502769L */
/** Delegate: andrew */

/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** Black */
@font-face {
  font-family: "SF Display";
  font-weight: 900;
  src: url("./fonts/SF-UI/SF-UI-Display-Black.otf");
}

/** Bold */
@font-face {
  font-family: "SF Display";
  font-weight: 700;
  src: url("./fonts/SF-UI/SF-UI-Display-Bold.otf");
}

/** Heavy */
@font-face {
  font-family: "SF Display";
  font-weight: 800;
  src: url("./fonts/SF-UI/SF-UI-Display-Heavy.otf");
}

/** Light */
@font-face {
  font-family: "SF Display";
  font-weight: 200;
  src: url("./fonts/SF-UI/SF-UI-Display-Light.otf");
}

/** Medium */
@font-face {
  font-family: "SF Display";
  font-weight: 500;
  src: url("./fonts/SF-UI/SF-UI-Display-Medium.otf");
}

/** Regular */
@font-face {
  font-family: "SF Display";
  font-weight: 400;
  src: url("./fonts/SF-UI/SF-UI-Display-Regular.otf");
}

/** Semibold */
@font-face {
  font-family: "SF Display";
  font-weight: 600;
  src: url("./fonts/SF-UI/SF-UI-Display-Semibold.otf");
}

/** Thin */
@font-face {
  font-family: "SF Display";
  font-weight: 300;
  src: url("./fonts/SF-UI/SF-UI-Display-Thin.otf");
}

/** Ultralight */
@font-face {
  font-family: "SF Display";
  font-weight: 100;
  src: url("./fonts/SF-UI/SF-UI-Display-Ultralight.otf");
}

/*---------------------------------------------------------------------------*
 * SF UI Text
 *---------------------------------------------------------------------------*/

/** Bold */
@font-face {
  font-family: "SF Text";
  font-weight: 700;
  src: url("./fonts/SF-UI/SF-UI-Text-Bold.otf");
}

/** Bold Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/SF-UI/SF-UI-Text-BoldItalic.otf");
}

/** Heavy */
@font-face {
  font-family: "SF Text";
  font-weight: 800;
  src: url("./fonts/SF-UI/SF-UI-Text-Heavy.otf");
}

/** Heavy Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 800;
  font-style: italic;
  src: url("./fonts/SF-UI/SF-UI-Text-HeavyItalic.otf");
}

/** Light */
@font-face {
  font-family: "SF Text";
  font-weight: 200;
  src: url("./fonts/SF-UI/SF-UI-Text-Light.otf");
}

/** Light Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 200;
  font-style: italic;
  src: url("./fonts/SF-UI/SF-UI-Text-LightItalic.otf");
}

/** Medium */
@font-face {
  font-family: "SF Text";
  font-weight: 500;
  src: url("./fonts/SF-UI/SF-UI-Text-Medium.otf");
}

/** Medium Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/SF-UI/SF-UI-Text-MediumItalic.otf");
}

/** Regular */
@font-face {
  font-family: "SF Text";
  font-weight: 400;
  src: url("./fonts/SF-UI/SF-UI-Text-Regular.otf");
}

/** Semibold */
@font-face {
  font-family: "SF Text";
  font-weight: 600;
  src: url("./fonts/SF-UI/SF-UI-Text-Semibold.otf");
}

/** Semibold Italic */
@font-face {
  font-family: "SF Text";
  font-weight: 600;
  font-style: italic;
  src: url("./fonts/SF-UI/SF-UI-Text-SemiboldItalic.otf");
}


/*---------------------------------------------------------------------------*
 * SF UI Text (New)
 *---------------------------------------------------------------------------*/

/** Bold */
@font-face {
  font-family: "SF Text 2";
  font-weight: 700;
  src: url("./fonts/SF-UI2/SFUIText-Bold.ttf");
}

/** Bold Italic */
@font-face {
  font-family: "SF Text 2";
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/SF-UI2/SFUIText-BoldItalic.ttf");
}

/** Heavy */
@font-face {
  font-family: "SF Text 2";
  font-weight: 800;
  src: url("./fonts/SF-UI2/SFUIText-Heavy.ttf");
}

/** Heavy Italic */
@font-face {
  font-family: "SF Text 2";
  font-weight: 800;
  font-style: italic;
  src: url("./fonts/SF-UI2/SFUIText-HeavyItalic.ttf");
}

/** Light */
@font-face {
  font-family: "SF Text 2";
  font-weight: 200;
  src: url("./fonts/SF-UI2/SFUIText-Light.ttf");
}

/** Light Italic */
@font-face {
  font-family: "SF Text 2";
  font-weight: 200;
  font-style: italic;
  src: url("./fonts/SF-UI2/SFUIText-LightItalic.ttf");
}

/** Medium */
@font-face {
  font-family: "SF Text 2";
  font-weight: 500;
  src: url("./fonts/SF-UI2/SFUIText-Medium.ttf");
}

/** Medium Italic */
@font-face {
  font-family: "SF Text 2";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/SF-UI2/SFUIText-MediumItalic.ttf");
}

/** Regular */
@font-face {
  font-family: "SF Text 2";
  font-weight: 400;
  src: url("./fonts/SF-UI2/SFUIText-Regular.ttf");
}

/** Semibold */
@font-face {
  font-family: "SF Text 2";
  font-weight: 600;
  src: url("./fonts/SF-UI2/SFUIText-Semibold.ttf");
}

/** Semibold Italic */
@font-face {
  font-family: "SF Text 2";
  font-weight: 600;
  font-style: italic;
  src: url("./fonts/SF-UI2/SFUIText-SemiboldItalic.ttf");
}

