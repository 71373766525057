.footer-container{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 720px) {
  .footer-container{
    display: none;
  }
}
